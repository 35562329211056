import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/Layout"
import ListNote from "@/components/ListNote"

import { SITE_TITLE } from "../../config"
import kebabCase from "lodash.kebabcase"

const TagTemplate = ({ data, pageContext }) => {
    const { edges } = data.allMdx
    const {
        tag,
        // slug,
        currentPage,
        // prevPagePath,
        // nextPagePath,
        // hasPrevPage,
        // hasNextPage,
    } = pageContext

    const pageTitle =
        currentPage > 0
            ? `All posts tagged with ${tag} - Page ${currentPage} - ${SITE_TITLE}`
            : `All posts tagged with ${tag} - ${SITE_TITLE}`

    return (
        <Layout title={pageTitle}>
            <h1>{kebabCase(tag)}</h1>
            <p>({edges.length})</p>
            <ListNote edges={edges} />
        </Layout>
    )
}

export const query = graphql`
    query TagPage($tag: String) {
        allMdx(
            # limit: $postsLimit
            # skip: $postsOffset
            filter: {
                frontmatter: { publish: { ne: false }, tags: { in: [$tag] } }
            }
            sort: { order: DESC, fields: fields___date }
        ) {
            edges {
                node {
                    ...postList
                }
            }
        }
    }
`

export default TagTemplate
